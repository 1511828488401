import React, { useEffect, useState }  from "react";
import Layout from "../_components/Layout/Layout";
import UserForm from "../_components/UserForm/UserForm";
import MainTitle from "../_components/MainTitle/MainTitle";
import PageSummary from "../_components/PageSummary/PageSummary";
import Database from "../_utils/Database";
import { goto } from "../_utils/Misc";
import moment from "moment";

const Account = () => {
    const [loading, setLoading] = useState(true);
    const [values, setValues] = useState({
        email: "",
        fullName: "",
        favouriteCuisine: "",
        postcode: "",
        firstTasteExperience: "",
        dobYear: "",
        gender: "",
        newsletterSignup: "",
    });

    useEffect(() => {
        if (!Database.getUserToken()) {
            goto("/");
            return null;
        }

        // Make request
        fetch(`${process.env.GATSBY_FABACUS_API_URL}v1/consumer`, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${Database.getUserToken()}`,
            },
        }).then(async(response) => {
            const responseObject = await response.json();

            let filterConsumerPreferences = (preferences, search) => {
                const filtered = preferences && preferences.filter(item => item.preference_name === search);
                if (filtered.length > 0) {
                    return filtered[0].preference_value;
                }
                return null;
            };

            if (responseObject && responseObject.consumer_id) {
                let dob = moment(responseObject.dob);

                setValues({
                    userId: responseObject.consumer_id,
                    email: responseObject.email,
                    fullName: responseObject.name,
                    favouriteCuisine: filterConsumerPreferences(responseObject.consumer_preferences, "preference_favourite_cuisine"),
                    postcode: filterConsumerPreferences(responseObject.consumer_preferences, "preference_postcode"),
                    firstTasteExperience: filterConsumerPreferences(responseObject.consumer_preferences, "preference_first_taste_experience"),
                    dobYear: dob.format("Y"),
                    gender: responseObject.gender,
                    newsletterSignup: responseObject.marketing_consent,
                });

                setLoading(false);
            } else {
                alert(`We cannot process your request now. Please try again later.`);
            }
        }).catch(e => {
            console.error("Fetch reward error", e);
            alert(`We cannot process your request now. Please try again later.`);
        });
    }, []);

    return (
        <Layout title="Account">
            <MainTitle>My details</MainTitle>

            {loading &&
                <PageSummary>
                    Loading...
                </PageSummary>
            }

            {!loading &&
                <UserForm
                    btnText="Update"
                    isAccountForm={true}
                    values={values}
                />
            }
        </Layout>
    );
};
export default Account;
